// /api/v1/users/reports/enrollment

import moment from 'moment';
import { PaginateApiResponse, getBaseUrl, getHeaders } from './common.ts';
import {
  FetchParametersParam,
  PaginationParams,
  SortingParams,
  addPaginationToFetchUrl,
  addParamsToFetchUrl,
  addSortingToFetchUrl,
} from './fetch.ts';

export async function fetchUserEnrollmentReport(
  params,
  sorting,
  pagination
): Promise<PaginateApiResponse<UserEnrollmentReportItem>> {
  const fetchURL = new URL('/api/v1/users/reports/enrollment', getBaseUrl());

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL);

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  json.items = json.items.map((item) => {
    // return the same thing but use moment to format the date in a reasonable way
    return {
      ...item,
      submitted: item.submitted
        ? moment(item.submitted).format('MM/DD/YYYY')
        : null,
    };
  });
  return json;
}

export async function fetchUserReport(
  params,
  sorting,
  pagination
): Promise<PaginateApiResponse<UserReportItem>> {
  const fetchURL = new URL('/api/v1/users/reports', getBaseUrl());

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL);

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  json.items = json.items.map((item) => {
    // return the same thing but use moment to format the date in a reasonable way
    return {
      ...item,
      acct_lastlogin: item.acct_lastlogin
        ? moment(item.acct_lastlogin).format('MM/DD/YYYY')
        : null,
      acct_role:
        item.acct_role === -1
          ? 'Administrator'
          : item.acct_role === 1
            ? 'Coodinator'
            : item.acct_role === 2
              ? 'Super Coordinator'
              : 'Unknown',
    };
  });
  return json;
}

export async function fetchUsers(
  params: FetchParametersParam,
  sorting: SortingParams,
  pagination: PaginationParams
): Promise<PaginateApiResponse<User>> {
  const fetchURL = new URL('/api/v1/users/fetch', getBaseUrl());

  if (!params.hasOwnProperty('include_deactivated')) {
    params.include_deactivated = 'false';
  }

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL);

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  json.items = json.items.map((item) => {
    // return the same thing but use moment to format the date in a reasonable way
    return item;
  });
  return json;
}

interface User {
  name: string;
  role: string;
  status: string;
  active_since: string; // Using string to represent ISO date-time
  last_login: string; // Using string to represent ISO date-time
  email: string;
  id: string;
  location_name: string;
  cbo_name: string;
  cbo_id: number | null; // Nullable number
  location_id: string;
  location_cbo_id: number;
  phone_number: string;
}
export interface UserEnrollmentReportItem {
  username: string;
  name: string;
  confirmation_number: string;
  card_id: number;
  card_type: string;
  card_number: string;
  account_number: string;
  submitted: string;
}

export interface UserReportItem {
  cbo_name: null;
  cbo_activity: null;
  loc_name: string;
  loc_activity: boolean;
  acct_name: string;
  acct_username: string;
  acct_role: number;
  acct_lastlogin: null;
}

export async function selfUserAction(action: string) {
  const options = {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({
      action: action,
    }),
  };

  const url = new URL(`/api/v1/me`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error('Something went wrong!');
  }

  return await resp.json();
}

export async function requestViewAsSession(username: string) {
  const options = {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({
      username: username,
    }),
  };

  const url = new URL(`/api/v1/me/view_as`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error('Something went wrong!');
  }
  removeAllLocalStorageSessionData();
}

export async function endViewAsSession() {
  const options = {
    method: 'DELETE',
    headers: await getHeaders(),
  };

  const url = new URL(`/api/v1/me/view_as`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error('Something went wrong!');
  }
  removeAllLocalStorageSessionData();
  localStorage.setItem('ceul', 'Administrator');
}

export function removeAllLocalStorageSessionData() {
  localStorage.removeItem('ceul');
  localStorage.removeItem('isAdministrator');
  localStorage.removeItem('modal-type');
  localStorage.removeItem('admin_org_info');
}

export interface MeObject {
  id: string;
  name: string;
  email: string;
  phone: { phone_number: string };
  image: string;
  card_enrollment_user_level: string;
  file_upload_allowed: boolean;
  location_id: string;
  cbo_id: string;
  roles: string[];
}

export type MeResponse = MeObject & {
  real_user?: MeObject;
  messages: string[];
};

type FailedMeResponse = { cause: string}

export async function getMe(): Promise<MeResponse | FailedMeResponse> {
  const options = {
    method: 'GET',
    headers: await getHeaders(),
  };

  const url = new URL(`/api/v1/me`, getBaseUrl());
  const resp = await fetch(url, options);

  try {
    return await resp.json();
  }catch(e) {
    const respText = await resp.text();

    return {cause: respText.includes('429') ? 'Rate Limit' : 'Unknown'}
  }
}

export type UpdateUserRequestBody = Partial<User> & {id: string, phone_type?: string}

export async function updateUser(body: UpdateUserRequestBody): Promise<User | {message: string, err: string}> {  
  const options = {
    method: 'PUT',
    headers: await getHeaders(),
    body: JSON.stringify({
      ...body,
      phone: {
        phone_number: body.phone_number,
        phone_type: body.phone_type
      }
    })
  };

  const url = new URL(`/api/v1/user/${body.id}`, getBaseUrl());
  const resp = await fetch(url, options).then(async (resp) => await resp.json()).catch(async (e) => {
    try {
      return {message: await e.json()}
    }catch {
      return {message: 'Something went wrong', err: e}
    }
  });

  return await resp;

}